.containerFooter{background-color:#f7a708;color:#000;margin-bottom:0!important;padding-top:5px!important;margin-top:20px!important;text-align:center;display: inline-block;width: 100%;}
.rowCopyright{text-align:center;color:#fff;background-color:#000000f5!important;padding-bottom:2px!important;height:100%}
.enlaceFooter{color:#000}
.enlaceFooter:hover{font-weight:700;color:#000}
.imagenRedes{margin-top:10px;padding:3px}
@media (max-width:980px){.containerFooter{width: 105%!important;}}
@media (max-width:768px){.containerFooter{font-size:12px;width: 110%!important;}}
@media(max-width:575px){
    .containerFooter{font-size:12px}h5{text-align:center}ul{margin:auto;width:100%}.parrafoFooter{text-align:center!important;margin:5px 0}
    .imagenRedes{margin-top:-8px;padding:10px}
}
@media (max-width:425px){.containerFooter{margin:0;padding:0 12px 0 0; width: 110%!important;}.zonaPago{display:grid}}
@media (max-width:370px){.containerFooter{width: 110%!important;}}