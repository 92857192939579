.li_filtros:hover,.p_filtros{cursor:pointer}.h5_titulosMenu,.headerAcordion:hover,.offcanvas-title{background:#f7a708}.p_filtros{text-align:left!important}li,ul{margin-left:0}.banner,.page-link{text-align:center}li{list-style:none}ul{padding-left:0!important}.h5_titulosMenu{height:40px;display:flex;align-items:center;padding-left:10px;color:#fff;font-size:15px}.row_breadcrumb{margin-left:8px;margin-right:8px;margin-bottom:20px;border:2px solid #000}.breacrumb_historial{display:flex;align-items:center}.b_textProducto{font-size:25px; padding-left: 3rem;}.breadcrumb{display:flex!important;align-items:center!important;margin-bottom:0;padding-top:5px;padding-bottom:5px}.accordion-button{padding:5px!important}.accordion-item{border:none!important}.page-item.active .page-link{z-index:3;color:#fff!important;background-color:#ffc107!important;border-color:#ffc107!important}.p_filtros:hover,.page-link{color:#ffc107!important}.p_filtros{padding:3px 0;margin:0!important}.pagination{width:300px!important}.divCategorias{max-height:400px}div::-webkit-scrollbar{width:12px!important}div::-webkit-scrollbar-thumb{-webkit-box-shadow:inset 0 0 6px #f7a708!important;border-radius:10px!important}div::-webkit-scrollbar-track{border-radius:10px!important;background-color:#fff!important;-webkit-box-shadow:inset 0 0 6px rgba(90,90,90,.7)!important}.offcanvas-title{width:100%!important;display:flex;align-items:center;color:#fff}#divOffcanvasCategorias,.accordion-button::after{display:none}.page-link{width:40px;padding:5px}

@media (max-width:1920px) {
    .cardProducto{width: 245px;}
    .conIva{text-align: center;}    
    .filtroCatalogo {width: 265px !important;}
    .imgCatalogo{padding-top: 15px;}
    .imgProducto{margin-left: 30px;}
    .catalogo{margin-left: 10px !important;}
    .imgCatalogo{width: 210px !important;}
    .divExclusivo{font-size: 16px;}
}
@media(max-width:1366px){
    .catalogo{margin-left: -40px !important;}
    .imgCatalogo{width: 200px !important;}
}

@media (max-width:1196px){
    #btnCatalogo {width: 180px !important;}
}
@media (max-width: 1025px){
    #cardTitle{font-size: 15px;}
    .divExclusivo{font-size: 12px;}
}
@media (max-width: 1195px){
    .cardProducto {width: 223px;}
}
@media (max-width: 1181px){
    
    .imgCatalogo {width: 203px !important;}
}

@media (max-width: 991px){
    .envioGratis {font-size: 14px;}
    #btnCatalogo {width: 170px !important;padding: 6px 0px 6px 0px;}
    .textStock{width: 200px;}
    .textIvaIncluido {font-size: 15px;margin-bottom: 0px;}
    .card-body {height: 375px !important;}
}
@media (max-width: 972px){
    .cardProducto {width: 206px;}
    .envioGratis, .textIvaIncluido, .divExclusivo{font-size: 11px;}
}
@media (max-width: 887px){
    .imgCatalogo {width: 181px !important;}    
}
@media (max-width: 880px){
    #btnCatalogo {width: 175px !important;}
}

@media (max-width: 850px){
    .cardProducto{height: 550px;}
    #cardTitle {font-size: 14px;}
    .textIvaIncluido{margin-bottom: 10px;}    
    .card-title {height: 90px!important;}
    .catalogoVertical {margin-top:0px;}
    .envioGratis {margin: 13px 10px;}
    .textIvaIncluido,.divExclusivo{margin-left: 1rem;font-size: 11px;}
    .textIvaIncluido{margin-left: 1rem;}
    #btnCatalogo {margin-top: -10px;margin-left: 10px;width: 136px !important;font-size: 13px;}
}
@media (max-width: 822px){
    #btnCatalogo {margin-top: -17px;}
    .catalogoVertical {margin-top: -16px;}
    .envioGratis {margin-top: 3px;}
    .textIvaIncluido {margin-top: -2px;}
}

@media (max-width: 820px){
    #cardTitle {
        font-size: 11px;
    }
    .card-title {
        height: 70px!important;
    }
    .catalogoVertical {margin-top: 0px;}
    .textIvaIncluido {margin-left: 5px;margin-top: 5px;}
    #btnCatalogo {margin-top: 0px;margin-left:10px;width: 135px !important;font-size: 12px;}
    #cardLanding {
        width: 175px;
        height: 385px;
    }
    .textStock {margin-top: 0px;}
    .cardProducto {
        height: 420px;
    }
    .imgProducto {
        width: 170px !important;
        height: 120px !important;
    }
    .textIvaIncluido {margin-left: 1rem;}
    .textIvaIncluido, .divExclusivo{margin-left: 1rem;}
    .envioGratis{margin-top: 16px;}
}
@media (max-width:768px){
    .filtroCatalogo {width: 200px !important;}
    .filtroSelect {width: 180px!important;}
    .textStock{margin: 0px 0px 0px 5px !important;font-size: 11px;}
    .textIvaIncluido, .divExclusivo, .envioGratis{font-size:13px}
    .envioGratis{margin-top: 6px;}
    #cardTitle {
        font-size: 12px !important;
    }
    .card-title {
        width: 154px!important;;
    }
    #cardLanding {
        width: 170px !important;
        height: 360px !important;
    }
    .btnLanding {
        width: 85%;
        margin-left: 10px;
    }
    .cardProducto {
        height: 390px;
    }
    .b_textProducto{font-size:18px;}
    .filtroSelect{width:130px;font-size:10px}.resultadoBusqueda{width:100%!important}.imgCatalogo{width:180px!important}.h5_titulosMenu{font-size:10px!important}.p_filtros{font-size:12px}
}
@media (max-width:714px){
    #btnCatalogo {margin-left: 10px;}
    .textIvaIncluido {margin-top: 6px;}
    .imgProducto {margin-left: -2px !important;}
}
@media (max-width:620px){
    .filtroCatalogo{width: 168px !important;}
    #btnCatalogo {width: 178px !important;}
}
@media (max-width:575px){#btnCatalogo {width: 135px !important;}#imgBanner{width:100%; max-height:100% !important}.imgCatalogo{margin:0;padding:0;width:200px}.breacrumb_historial{font-size:10px}#divAccordionCategorias{display:none}#divOffcanvasCategorias{display:block}.banner{height:200px;}}
@media (max-width:563px){.filtroCatalogo {width: 210px !important;}.filtroSelect {width: 215px!important;}.b_textProducto{padding-left: 1rem; font-size:20px;}}
@media (max-width:501px){.filtroCatalogo {width: 201px !important;}}
@media(max-width:490px){
    .imgProducto {height: 100px !important;}
    #btnCatalogo{width: 182px !important;}
    .filtroCatalogo {width: 190px !important}
    .filtroSelect {width: 190px!important;}
    .card-title {width: 185px!important;font-size: 15px !important;height: 76px!important}
    .textIvaIncluido {margin-left: 36px}
    .divExclusivo {margin-top: 0px;margin-left: 40px}    
    .envioGratis, .textIvaIncluido, .divExclusivo{font-size: 13px;margin-left: 2rem}    
    .card-text #cardProducto{font-size: 14px}
    .card-title {height: 65px !important;}
    .cardProducto {
        height: 370px;
    }
}
@media(max-width:459px){
    .filtroCatalogo {width: 180px !important}
    .filtroSelect {width: 180px!important;}

}
@media(max-width:475px){.divExclusivo {margin-left: 0rem}
.envioGratis, .textIvaIncluido, .divExclusivo{font-size: 14px;}  }
@media(max-width:440px){
    .envioGratis, .textIvaIncluido {margin-left: 0rem; margin-top: 0px; margin-bottom: 0px;}
    #btnCatalogo{width: 170px !important;margin-left: 10px;}
    .filtroSelect{width: 170px !important;}
    .cardProducto{height:390px!important}
    .imgCatalogo{width:150px!important;height:390px}
    .banner{width:100%;margin-top:0;height: auto;}
    .divCanvas{width:100%;}
    #cardProducto,#cardTitle{font-size:10px;margin:0 !important}
    .precio{margin-left:27px}
    .textoTachado{margin-left:2px; display: grid;}
    .resultadoBusqueda{width:100%!important;margin-top: 180px;}
    .imgProducto {width: 200px !important;height: 120px !important;}
    .textStock {margin-left: 0px !important;}
    #cardProducto {font-size: 13px;}    
    .filtroCatalogo{padding: 0px;width: 165px !important}
}
@media(max-width:425px){.textoTachado{margin-left:0}.precio{margin-left:25px}}
@media (max-width:421px) {.resultadoBusqueda{margin-top: 160px;}}
@media (max-width:420px) {.resultadoBusqueda{margin-top: 160px;}}
@media (max-width:419px) {.imgCatalogo{margin: 6px;margin-left: 3rem}.filtroCatalogo{width: 125px !important;}.filtroSelect{width:140px !important}}
@media(max-width:390px){
    .imgProducto {width: 200px !important;}
    .cardProducto {width: 210px !important;}
    .card-title {font-size: 10px;}}#btnCatalogo{width: 96px;}
    .filtroCatalogo{width: 175px;}
@media(max-width:377px){.filtroCatalogo{width: 110px !important;}.filtroSelect{width:120px !important}.precio{margin: 0px !important;}}
@media(max-width:376px){.resultadoBusqueda{margin-top: 160px;}}
@media(max-width:375px){.filtroCatalogo.banner{height:130px}}
@media(max-width:320px){
    #imgProducto {width: 100%!important;}
    .envioGratis{margin-top: 14px;}
    .card-text {font-size: 12px;margin-top: -5px !important;}
    #btnCatalogo{height: 35px; margin-top: 15px;}
    .textIvaIncluido{margin-top: 0px !important;}
    #cardLanding {
        width: 170px !important;
        height: 340px !important;
    }
}
