.div_padre_productSingle {
    margin-top: 0;
    padding-top: 0
}

.div_hijo1_prodctSingle {
    padding: 75px;
    display: flex;
    flex-direction: row;
    gap: 50;
    flex-wrap: wrap
}

.div_imagen_producto {
    margin: 0 25px 25px;
    width: 500px !important;
    height: 500px;
    display: flex;
    flex-grow: 2
}

.div_descripcion_producto {
    margin: 0 25px 25px;
    width: 500px;
    height: 500px;
    flex-grow: 2
}

.nombre_producto {
    text-align: left;
    margin-bottom: 5px
}

.div_descuento,
.div_precio {
    display: flex;
    align-items: center
}

.div_precio_metros {
    padding-bottom: 20px
}

.div_descuento {
    width: 80px;
    height: 40px;
    border: solid balck;
    border-radius: 10%;
    justify-content: center;
    background-color: red;
    color: #fff
}

.div_precio {
    align-content: center
}

.label_precio,
.text_precio {
    align-items: center;
    display: flex
}

.label_precio {
    margin-right: 10px;
    font-size: 30px;
    color: #f7a708;
    justify-content: center
}

.text_precio {
    justify-content: center;
    margin-bottom: 0
}

.div_input_button {
    margin-bottom: 30px;
    display: flex;
    align-items: center
}

.input_productSingle {
    text-align: center;
    border-radius: 5%;
    width: 150px;
    height: 40px;
    margin-right: 20px;
    border: none;
    background-color: #f0f8ff
}

.button_productSingle {
    background-color: #000;
    border-radius: 10%;
    color: #fff;
    height: 40px
}

h6 {
    margin: 0
}

hr {
    margin: 10px 10px 10px 0
}

.div_hijo2_productSingle {
    margin: 0 0 25px;
    padding: 0
}

.title {
    text-align: left
}

.div_grupo_imagenes {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 500px;
    flex-wrap: wrap;
    padding-left: 25px;
    height: 75px
}

.div_imagen {
    width: 75px;
    height: 75px
}

.filaPrecio {
    height: 60px
}

img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain
}

#Banner {
    width: 100%
}

@media(max-width:768px) {
    .btnAdicionar {
        margin: 2px 0 10px;
        height: 35px
    }
}

@media(max-width:320px) {
    .btnAdicionar {
        margin-top: 0 !important
    }
}

@media (max-width:570px) {
    #Banner {
        width: 100%;
        height: 70%;
        padding: 10px
    }
}

.btnAdicionar {
    font-size: 20px
}

.archivo {
    border: none
}