@font-face {
    font-family: 'Pacifico';
    src: local('Pacifico Regular'), local('Pacifico-Regular'), url('https://fonts.gstatic.com/s/pacifico/v12/FwZY7-Qmy14u9lezJ-6H6MmBp0u-.woff2') format('woff2');
    font-display: swap;
  }

.slick-prev:before,
.slick-next:before {
    color: #85929E;
    padding: 28px 28px 0px 0px;
    margin-block: -40px;
}

.carruselResponsive{
    padding:10px 0px;
}
.carruselMarca{
    width: 100%;
    height: 100px;
}


.btn-outline-warning{
    color: #f7a708 !important;
    border-color: #f7a708 !important;
    font-weight: 500;
}

.btn-outline-warning:hover{
    color: #000 !important;
    background-color: #f7a708 !important;
    border-color: #f7a708 !important;
    font-weight: normal;
}

h4{
    text-align: center;
}

a{
    
    font-display: fallback !important;;
    text-decoration: none;
    color: #000;
}

a:hover{
    color:#f7a708;
}

.imgLogo{
    width:250px;
}

.imgMarca{
    width: 130px !important;
}


.imgProducto{
    width: 180px;
    height: 250px;
    margin-left: 15px;
}


.textoTachado{
    text-decoration: line-through;
}

.colorTexto{
    color: #f7a708 !important;
    text-align: center;
    margin-left: 1rem;
    display: grid;
}

.colorTextoLanding{
    color: #f7a708 !important;
    text-align: center;
    margin-left: 1rem;
    display: grid;
}

.divInfoAdicionalCard{
    position: absolute;
    display: flex;
}

.divDescuento{
    width: 70px;
    height: 30px; 
    background-color: #000;
    color: #fff;
    padding: 2px;
    border-radius: 3px;
    margin: 5px 6px;
}
/* .divExclusivo{
    
    height: 30px; 
    background-color: #000;
    color: #fff;
    padding: 2px;
    border-radius: 3px;
    margin: 5px 6px;
} */

.divNuevo{
    width: 70px;
    height: 30px; 
    background-color: #f7a708;
    color: #fff;
    padding: 2px;
    border-radius: 3px;
    margin: 5px 6px;
    text-align: center;
}

.divNuevoUjueta{
    width: 110px;
    height: 30px; 
    background-color: #f7a708;
    color: #fff;
    padding: 2px;
    border-radius: 3px;
    margin: 5px 6px;
    text-align: center;
}
.ocultar{
    display: none;
}

.card-title{
    height: 110px !important;
    width: 178px;
    font-size: 18px;
}

.btn-wsp {
    position: fixed;
    bottom: 5%;
    right: 5%;
    border-radius: 100%;
    box-shadow: 0px 1px 10px rgb(0 0 0 / 30%);
    cursor: pointer;
}

.textAgotado{
    color: red;
    text-align: center;
    font-weight: bold;
    font-size: 15px;
}
.textStock{
    text-align: left;
    font-size: 0.875em;
}

.filaPrecio{
    text-align: center;
    height: 60px;
}

.descripcion{
    font-size: 16px;
}

@media (max-width: 1024px) {
    .cardProducto{
        width: 215px;
    }
    .precioDespues{
        font-size: 14px;
        margin: 0px;
        padding: 0px;
    }
    .descripcion{
        display: block;
        font-size: 15px;
        width: 180px;
    }
    .textAgotado{
        font-size: 14px;
    }
    
}
.card-body{
    flex: 1 1 auto;
    padding: 0.5rem 1rem;
}
@media (max-width: 1024px) and (max-height: 521px) {
    .card-body {
        height: 400px !important;
    }
}
@media (max-width: 973px) {
    .textAgotado{margin-bottom: 0px;}
    .cardProducto{
        height: 590px;
    }
}

@media (max-width: 820px) {
    .carruselResponsive{
        padding: 10px 0px;
    }
    .imgProducto{
        width:130px !important;
        height:100px !important;
    }
    .cardProducto{
        width: 165px;
    }
    .card-body{
        height: 450px;
        font-size: 10px;
    }    
    .card-title{
        font-size: 10px;
        width:100%;
        height: 180px !important;
        margin-block: 0px;
    }
    .card-text{
        font-size: 11px;
        margin-block: 0px;
        margin-left: 6px;
    }
    .descripcion{
        font-size: 11px;
        width: 100%;
        display: grid;
        
    }
    /* Precion antes y despues */
    .centrarVertical {
        text-align: center;
        display: inline;
        font-size: 12px;
        margin-left: 10px;
    }
    
    .colorTexto{
        font-size: 11px !important;
    }
    .colorTextoLanding{
        font-size: 12px !important;
    }
    .precioDespues{
        display: flex;
        width: 200px;
    }
    .colTexto{
        width: 94px !important;
    }
    .filaPrecio{
        display: contents;        
        font-size: 12px;
    }
    /* Btn  de agragar al carrito */
    .btn-outline-warning{
        font-size: 11px;
        width: 98%;
        height: 28px;
        padding: 0px;
    }

    /* Etiquetas */
    .divDescuento{
        font-size: 11px;
        width: 45px;
        height: 20px;    
    }
    .divNuevo{
        font-size: 11px;
        width: 45px;
        height: 20px;    
    }
    .divNuevoUjueta{
        font-size: 11px;
        width: 90px;
        height: 20px;    
    }
}
@media (max-width: 768px) {
    .imgProducto{
        width: 145px;
        height: 100px;
    }

    /* LANDING PAGE */
    .card-body {
        height: 213px;
        padding: 5px 8px 4px 8px;
    }
    .card-title{
        width:100%;
        height: 55px !important;
        margin-block: 0px;
        font-size: 15px;
    }
    .card-text{
        font-size: 10px;
        margin-block: 0px;
    }
    .descripcion{
        font-size: 10px;
        width: 100%;
        display: grid;
    }
    .colTexto {
        width: 80px !important;
    }
    /* Precion antes y despues */
    .centrarVertical {
        text-align: center;
        display: inline;
        font-size: 10px;
    }
    .precioAntes{
        height: 25px;            
    }
    .precioDespues{
        text-align: center;
        display: flex;
        width: 100%;
        margin-left: 20px;
    }
    /* Btn  de agragar al carrito */
    .btn-outline-warning{
        font-size: 11px;
        width: 100%;
        height: 30px;
        padding: 0px;
    }    
    /* Etiquetas */
    .divDescuento{
        font-size: 11px;
        width: 45px;
        height: 20px;    
    }
    .divNuevo{
        font-size: 11px;
        width: 45px;
        height: 20px;    
    }
    /* CATALOGO */
    #cardTitle{
        font-size: 8px !important;
    }
    #card{
        margin: 0;
        width: 100% !important;
    }
    .cardProducto {
        width: 155px;
    }
    /* Stock */
    .textStock{
        font-size: 11px;
    }
    .textAgotado{
        font-size: 13px;
        margin: 5px;
    }
    /* Precio Pagina */
    .divExclusico{
        font-size: 11px;
    }
    /* INICIAR SESSION */
    .iniciarSession{
        font-size: 12px;
    }
    .vista{
        width: 13%;
        height: 32px;
    }
    /* MODAL AGREGAR AL CARRITO */
    .btnAgregar{
        font-size: 15px !important;
    }
    .flecha{
        width: 850px !important;
    }
}
@media (max-width: 768px) and (max-height: 521px) {
    .card-body {
        height: 220px !important;
    }
    .flecha{
        width: 850px !important;
    }
}
@media (max-width: 440px) {
    .imgCatalogo {
        width: 101px !important;
    }
    #card {
        height: 300px !important;
    }

    .cardProducto {
        width: 220px !important;
        height: 420px !important;   
    }
    .card-title {
        text-align: center;
        width:100%;
        height: 60px !important;
    }
    .carruselResponsive{
        padding: 10px 0px 10px 0px;
        margin-left: 10px;
    }
    .precioDespues{
        padding: 0px;
        margin-left: 4px;
    }
    .colTexto {
        width: 80px !important;
    }
    .slider{
        width: 90% !important;
        margin-left: 45px !important;
    }
    .vista{
        width: 11%;
        height: 32px !important;
    }
    .input-group{
        margin: 0px 0px 8px 0px !important;
    }
    .btnAgregar{
        font-size: 14px !important;
    }
    .btnAgregar{
        height: 35px !important; 
    }
    .flecha{
        width: 450px !important;
        margin-left: -30px !important;
    }
    .card-text{
        margin-top: 11px !important;
    }
    .card-title-descripcion{
        height: 35px !important;
    }
}
@media (max-width: 440px) and (max-height: 521px) {
    .card-body {
        height: 210px !important;
    }
    .flecha{
        width: 450px !important;
        margin-left: -30px !important;
    }
}
@media (max-width: 425px) {
    .precioDespues {
        padding: 0px;
        margin-left: 10px;
    }
    .textoTachado {
        margin-left: 11px !important;
    }
    .flecha{
        width: 430px !important;
        margin-left: -25px !important;
    }
    .sliderBanner{
        padding: 5px;
    }
}
@media (max-width: 480px) {
    .descripcion{
        display: block;
        font-size: 8px;
    }
    .btn-outline-warning{
        font-size: 11px;
        height: 25px;
    }
    .colorTexto{
        font-size: 9px !important;
    }
    .colorTextoLanding{
        font-size: 12px !important;
    }
    .cardProducto {
        width: 120px;
    }
    .centrarVertical{
        display: contents;
    }
    .card-text {
        font-size: 11px;
        margin-top: 10px;
    }
    /* CATALOGO */
    
    .divExclusico{
        font-size: 9px;
    }
    .textoTachado{
        margin-left: 9px;
    }
    .divExclusico{
        font-size: 10px;
    }
    /* MARCAS IMG*/
    .imgMarca{
        width: 100px !important;
    }
}
@media (max-width: 540px) {
    .flecha {
        width: 610px !important;
    }
}
@media (max-width: 414px) {
    .h4{
        font-size: 12px;
    }
    .colorTextoLanding{
        font-size: 14px !important;
    }
} 
@media (max-width: 377px) {
    .carruselResponsive {
        padding: 10px 0px;
    }
    .card-text{
        margin-top: 13px !important;
    }
    
    .centrarVertical{
        display: flex !important;
    }
    .divExclusivo{
        font-size: 10px;
    }
    .textAgotado{
        font-size: 13px;
    }
    .textIvaIncluido{
        margin-top: 0px;       
    }
    .imgMarcas{
        padding: 0px;
        margin: 0px;
    }
    .flecha{
        width: 350px !important;
        margin-left: -25px !important;
    }
    .sliderBanner{
        padding: 5px;
    }

    .cardProducto{
        width: 120px !important;
        height: 450px !important;      
    }
}

@media (max-width: 380px) {
    
    .cardProducto {
        width: 100px;
    }
    .flecha{
        width: 380px !important;
        margin-left: -25px !important;
    }
    .sliderBanner{
        padding: 5px;
    }
    .card-title{
        margin-left: -10px;
        text-align: center;
        width: 117%;
        height: 60px !important;
    }
} 
@media (max-width: 320px) {
    .card-body{
        padding: 8px;
    }
    .carruselResponsive{
        padding: 10px 10px;
    }
    .cardProducto{
        width: 95px;        
        height: 340px !important;
    }
    .imgProducto {
        width: 82px;
        height: 90px;
    }
    .card-body {
        padding: 5px;
        height: 180px;
    }
    .card-text{
        font-size: 7px;
        min-height: 5px;
    }
    .card-title{
        height: 55px !important;
    }
    #cardProducto {
        margin-top: 5px;
    }
    .centrarVertical {
        padding: 0;
    }
    .colTexto {
        padding: 0px 23px 0px 0px;
    }
    .descripcion {
        font-size: 7px;
    }
    .divDescuento {
        font-size: 9px;
        width: 36px;
        height: 16px;
    }
    .divNuevo {
        font-size: 9px;
        width: 40px;
        height: 16px;
    }
    .divNuevoUjueta {
        font-size: 9px;
        width: 70px;
        height: 16px;
    }
    .imgMarca{
        width: 85px !important;
    }
    h4{
        font-display: fallback !important;
        font-size: 18px;
    }
    .vista{
        margin-top: 0px !important;
    }
    .btnIngresar{
        margin-top: 0px !important;
    }
    label{
        font-size: 12px;
    }
    .input-group{
        margin: 0px 0px 8px 0px !important;
    }
    .iniciarSession{
        font-size: 9px;
    }
    .btnAgregar{
        margin-top: 0px !important;
    }
    .textAgotado{
        font-size: 8px;
    }
    .btnLanding{
        margin-bottom: 35px;
    }
    
    .flecha{
        width: 300px !important;
        margin-left: -25px !important;
    }
    .sliderBanner{
        padding: 5px;
    }
} 
@media (max-width: 320px) and (max-height: 521px) {
    .card-body {
        height: 210px;
    }
}
@media (max-width: 270px) {
    .cardProducto{
        width: 82px;
    }
    .colorTexto{
        font-size: 7px !important;
    }
    .colorTextoLanding{
        font-size: 7px !important;
    }
    .btn-outline-warning{
        font-size: 8px;
    }
    
    .flecha{
        width: 270px !important;
        margin-left: -20px !important;
    }
} 

.banner{
    width: 100%;
    height: 20%;
}

.imgGrande{
    width: 100% !important;
    height: 15% !important;
    padding-left: 20px;
    margin-left: -5px;
}
.slider{
    width: 80%;
    margin: 0 auto;
    padding: 0;
    text-align: center;
    /* margin-left: -70px; */
}
.sliderBanner{
    width: 100%;
}
.flecha{
    /* border: 2px solid black; */
    width: 1200px;
    height: 10%;
    margin-left: -70px;
}
.flecha .slick-prev {
    display: none !important;
}
.flecha .slick-next {
    display: none !important;
}
.flechas .slick-prev {
    display: none !important;
}
.flechas .slick-next {
    display: none !important;
}

.divExclusico{
    height: 20px;
    display: block;
}
.envioGratis{
    color:red;
    display: block;
    margin-top: 16px;
}
.textIvaIncluido{
    margin-top: 10px;
}
.banner {
    display: block;
  }
/* banner */
.flecha{margin-left: 1rem;margin-top: 0px }
@media (max-width:2560px) {
    #instagram{ height: 130px !important; }
    #instagram{ width: 481px !important; }
}
@media (max-width:1440px) {
    .flecha{ width: 1250px !important; }
    #instagram{ height: 130px !important; }
    #instagram{ width: 481px !important; }
}
@media (max-width:13399px) {
    .flecha{ width: 1140px !important; }
}
@media (max-width:1187px) {
    .flecha{ width: 1125px !important; }
}
@media (max-width: 1191px){
    .flecha {width: 1000px !important;}
}
@media (max-width: 1163px){
    .flecha {width: 980px !important;}
}
@media (max-width: 1134px){
    .flecha {width: 900px !important;}
}
@media (max-width: 1000px){
    .flecha {width: 835px !important;}
    #instagram{ width: 355px !important; }
    #instagram{ height: 120px !important; margin-left: 80px;}
}
@media (max-width: 910px){
    .flecha {width: 800px !important;}
}
@media (max-width: 880px){
    .flecha {width: 760px !important;}
}
@media (max-width: 850px){
    .flecha {width: 700px !important;}
}
@media (max-width: 800px){
    .flecha {width: 650px !important;}
}
@media (max-width: 768px){
    #instagram{ height: 120px !important; margin-left:0px;}
}
@media (max-width: 714px){
    .flecha {width: 600px !important;margin-top: -10px !important;}
}
@media (max-width: 655px){
    .flecha {width: 580px !important;margin-left: 25px;}
}
@media (max-width: 620px){
    .flecha {width: 540px !important;margin-left: -15px;}
}
@media (max-width: 575px){
    .flecha {margin-top: -30px !important;}
    .redes {display: none;}
}
@media (max-width: 536px){
    .flecha {margin-top: -20px !important;margin-left: 15px;}
}
@media (max-width: 523px){
    .flecha {width: 500px !important;}
}
@media (max-width: 490px){
    .flecha {width: 450px !important;margin-left: 25px}
}

@media (max-width: 450px){
    .flecha {margin-left: 10px;}
}

@media (max-width: 440px){
    .flecha {margin-left: 10px !important;width: 430px !important;}
    .slider {margin-left: 25px !important;}
}
@media (max-width: 419px){
    .flecha {margin-left: -25px !important;width: 409px !important;}
}
@media (max-width: 419px){
    .flecha {margin-left: -25px !important;width: 409px !important;}
}
@media (max-width: 385px){
    .flecha {margin-left: -14px !important;width: 383px !important;}
}
@media (max-width: 377px){
    .flecha {margin-top: 25px !important;margin-left: -25px !important;width: 365px !important;}
    #instagram{ height: 120px !important; }
    #instagram{ width: 320px !important; }
}
@media (max-width: 375px){
    .flecha {margin-top: 0px !important;margin-left: -25px !important;width: 365px !important;}
}
@media (max-width: 345px){
    .flecha {margin-left: -7px !important;width: 345px !important;}
}
@media (max-width: 320px){
    .flecha {margin-left: -25px !important;}
}
