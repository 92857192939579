.btnInfoLogin,.btnSuiteFractal{background:0 0;border:none}.btnForm,.nav-link{text-align:center}.nav,.nav-item{font-size:12px}.botonIniciarSesion{display:flex;justify-content:center;align-items:center}.colFiltro,.nav-link::after{display:none}.icon_sesion{margin-right:5px}.colBtnInfoLogin{background:0 0;padding:0!important;cursor:pointer;max-height:70px;max-width:75px}.btnSuiteFractal,.namePanel{font-size:10px;text-align:center;width:100%;padding:3px}.btnInfoLogin{width:100%;}.conf{height:40px}.numberCar{height:0;width:0}.btn .badge{top:-10px!important;border-radius:15px}.badge{background:#f7a708!important}.btnCanvas{border:none;background:0 0;margin:0}.nav{width:100%;margin:0;align-items:center;justify-content:center}.nav-link{color:#000;font-weight:700;padding:0;height:10%;max-width:115px;white-space:initial}.colFiltro,.filas{padding:10px}.dropdown-menu{margin-top:15px!important;width:100vh}.nav-item{text-align:left!important;color:#000!important}.lineas{font-size:15px}.nav-item.show .nav-link{color:#f7a708!important}.lineas:hover,.nav-link:hover,.sublineas:hover{color:#f7a708!important;cursor:pointer}.filtroMenu{width: 100%;height: 100%;cursor: pointer;padding: 0;border: 0px;
}.flechaCanva{height:19%}.accordion-button{height:48px}.columna{width: 21% !important;}
@media (max-width:1440px) {
    .contendorHeader, .divNac{max-width: 1440px;}
    .colLogo{padding-left: 90px;}
    .text_iniciarSesion{font-size:13px}.icon_sesion{margin-right:5px}.botonIniciarSesion{display:flex;justify-content:center;align-items:center}.colBtnInfoLogin{max-width:60px;max-height:55px}.btnInfoLogin{font-size:15px;max-height:55px}.namePanel{font-size:10px}.btn .badge{font-size:9px}.columna{ width: 24% !important;}
}
@media (max-width:1141px){
    .nav, .nav-item{font-size: 11px;}
}
@media (max-width:1090px){
    .nav, .nav-item{font-size: 10px;}
}
@media (max-width:1045px){
    .nav, .nav-item{font-size: 9px;}
}
@media (max-width:1045px){
    .contendorHeader, .divNac{max-width: 1020px!important;}    
}
@media (max-width: 1040px){
    .contendorHeader, .divNac{max-width: 1040px;}    
}
@media (max-width:1024px){
    .dropdown-menu{width:130vh}
    .nav-link{max-width:85px}
    .nav-item{font-size:11px}
    .columna{ width: 23% !important;}
}
@media (max-width:991px) {
    .contendorHeader, .divNac{max-width: 991px!important;}
    .colLogo{padding-left: 50px;} 
}
@media (max-width:914px){
    .nav-link{max-width: 83px;font-size: 10px;}
}
@media (max-width:899px){
    .colBtnInfoLogin {max-width: 57px;}
}
@media (max-width:868px){
    .nav-link{max-width: 80px;}
}
@media (max-width: 854px){
    .colBtnInfoLogin {max-width: 50px;}
    .btnSuiteFractal {font-size: 9px;}
    .btnInfoLogin {font-size: 13px;}
}
@media (max-width: 850px){
    .nav-link {max-width: 74px;font-size: 8px;}
}
@media (max-width: 820px), (max-height:1180px){
    .buscar{width: 40px!important;height: 40px!important;}
}
@media (max-width:768px){
    .btnInfoLogin{font-size:16px;max-height:50px;max-width:50px;width:48px;padding:0}.botonIniciarSesion{display:flex;justify-content:center;align-items:center}.colBtnInfoLogin{max-width:45px;max-height:45px;display:flow-root}.buscar{width:40px!important;height:40px!important}.dropdown-menu{width:130vh}.columna{padding:0 5px 8px 17px; width: 35% !important;}.nav-link{max-width:60px; font-size: 8px;}.lineas{font-size:12px}.nav-item{font-size:8px}
    
}
@media (max-width: 675px){
    .colBtnInfoLogin {max-width: 40px}
}
@media (max-width: 655px){
    .nav-link {max-width: 50px;font-size: 7px;}
    .lineas{font-size: 9px !important;}
    .columna{padding-left: 5px !important;}
}
@media (max-width: 558px){
    .nav-link {font-size: 6px;}
}
@media (max-width: 540px){
    .filtroMenu {height: 36px !important;}
}
@media (max-width:600px){
    .colBtnInfoLogin,.namePanel,.nav,.suiteFractal{display:none}.colFiltro{display:contents}
    .iniciarSesion{display: block !important;}
    .namePanel{display: revert;}
    .contendorHeader{margin-left: 20px;}
    .btnMenu {margin: -10px 0 0 !important;}    
}
@media (max-width:540px){
    .colBtnInfoLogin{max-width:30px;max-height:35px}.btnInfoLogin{font-size:15px;text-align:center}.btn .badge{font-size:8px}
    .nav-link {font-size: 6px;}
    .colBtnInfoLogin{max-width: 50px;}
    .contendorHeader{margin:10px 0 0 20px!important}
    .btnInfoLogin{font-size: 20px;}
    .colLogo {width: 60%;}
    .imgGrande{width: 65% !important;}
    .namePanel{display:none}
    .btnSuiteFractal{color:transparent}
}
@media (max-width:490px){.colBtnInfoLogin{max-width:25px}.btnInfoLogin{font-size:18px;}}
@media (max-width:440px){.banner{width:100%;height:172px;margin-top:-25px}.divCanvas{width:100%;}.textBuscador{font-size:12px;height:30px}.buscar{width:25px!important;height:30px!important}.colLogo{width:60%!important}.contendorHeader{margin:10px 0 0 20px!important}.colFiltro{display:contents}.colBtnInfoLogin,.namePanel,.nav{display:none}.iniciarSesion{display: block !important;}.btnInfoLogin {padding: 0px;width:20px; height: 20px;}.btnMenu{margin:-14px 0 0;}.formuarioBuscador{width:100%}.contenedorCanvas{display:flex}.canvaAbrir{height:100%;padding:0 35px 0 0!important}.lineaCanvas{width:100%;min-width:250px}.dropdown-toggle{font-size:12px;height: 30px; padding-left: 0px;}}
@media(max-width:414px){
    .btnMenu{margin: -14px 0 0!important;font-size: 30px;}
}
@media(max-width:375px){
    .filtroMenu{height: 28px !important;width: 30px;}
    .filtroCatlogo{width:146px}
    .buscar{margin-top:0!important}
    .lineaCanvas{min-width:160px}
    .flechaCanva{font-size:15px}
    .btnMenu {margin: -17px 0 0!important;font-size: 21px;}
}

@media(max-width:360px){
    .btnMenu {margin: -17px 0 0!important;}
}

@media(max-width:320px){
    .contenidoTexto{font-size: 10px;}
    .colLogo{width:60%!important}
    .filtroMenu{height: 26px !important;}
    .btnMenu{margin: -20px 0 0!important;font-size: 18px;}
}

.dropdown-menu-buscador {
    width: 40vh !important;
    margin-top: 0px !important;
}
