.btnLanding{width: 100%;}
#cardLanding{width:260px; height: 620px;}
.card-title-descripcion{height: 76px !important; width: 225px;}
.btnLanding{ width: 100%;}.catalogoVertical{width:100%;text-align:center}.rowPrecio{display:none!important}
.colorTexto{font-size: 19px!important;}
@media(max-width:1440px){    
    .container-xl, .container-lg, .container-md, .container-sm, .container {max-width: 1230px;}
    .filaPrecio {height: 60px;width: 100%;margin-left: 6px;}
    #cardLanding {width: 238px;height: 620px;}
    .card-title-descripcion {height: 80px !important; width: 200px;}
}
@media(max-width:1321px){
    #cardLanding{width: 220px;}
    .centrarVertical{width: 41% !important;padding: 0px;}
    .colorTexto{margin-left: 1rem; font-size: 18px!important;}
    .card-title-descripcion{width: 190px;height: 90px !important;}
}
@media (max-width:1181px){
    .cardTitle{font-size: 16px !important;}
    .container-xl, .container-lg, .container-md, .container-sm, .container {max-width: 1080px;}
}
@media (max-width:1144px){
    .container-xl, .container-lg, .container-md, .container-sm, .container {max-width: 991px;}
}
@media (max-width:1048px){
    .container-xl, .container-lg, .container-md, .container-sm, .container {max-width: 915px;}
}
@media (max-width:1024px){    
    #cardLanding{width:235px;height: 630px}
    .card-title-descripcion{height: 90px !important; }
}
@media (max-width:991px){
    #imgProducto{width:170px;height:179px;margin:5px}
    #cardProducto,#cardTitle{font-size:15px}
    #card{width:80%}
    .container-xl, .container-lg, .container-md, .container-sm, .container {max-width: 875px;}
    .imgProducto {width: 145px;height: 170px;}
    #cardLanding {width: 210px;height: 540px;}
    .imgProducto {margin-left: 30px;}
    .textStock, .textoTachado {font-size: 13px;}
    .colorTexto{font-size: 13px!important;}
}

@media (max-width:910px){
    .container-xl, .container-lg, .container-md, .container-sm, .container {max-width: 855px;}
}
@media (max-width:880px){
    .container-xl, .container-lg, .container-md, .container-sm, .container {max-width: 820px;}
}
@media (max-width:850px){
    .container-xl, .container-lg, .container-md, .container-sm, .container {max-width: 800px;}
    #cardLanding {width: 186px;}
    .descripcion {display: block;font-size: 15px;width: 152px;}
    .centrarVertical {width: 55% !important;margin-left: -12px;}
}
@media (max-width:820px){    
    .container-xl, .container-lg, .container-md, .container-sm, .container {max-width: 723px;}
    #cardLanding {width: 170px;height: 330px;}
    .card-title-descripcion {height: 60px !important;width: 142px;}
    .centrarVertical {margin-left: 30px;}
    .descripcion {font-size: 13px;width: 145px;}
    .centrarVertical {margin-left: 27px;}
    .textoTachado{margin-left: 0rem !important;}
    .colorTexto{margin-left: 1rem;}
}
@media (max-width:768px){
    .container-xl, .container-lg, .container-md, .container-sm, .container {max-width: 695px;}
    .catalogoVertical{height:20px;text-align:center}
    #imgProducto{width:140px;height:140px}
    #cardProducto,.divExclusivo{font-size:12px}
    #card{height:260px!important}
    .textIvaIncluido{margin-top:0}
    .textStock{font-size:10px;margin:0 0 5px}
    .precio{font-size:15px}
    .precioSInDescuento {margin-left: 0px;padding: 0px;}
    .precioDespues{margin-left: 0px !important; }
    #cardLanding{width:170px !important;height: 310px !important}
    .descripcion {font-size: 12px;}
    .colorTexto{margin-left: 1rem!important;font-size: 18px!important;} .textoTachado {margin-left: 2rem;font-size: 11px;}
}
@media (max-width:714px){    
    .container-xl, .container-lg, .container-md, .container-sm, .container {max-width: 635px;}
    .card-title-descripcion{width: 135px;}
    #cardLanding {width: 70% !important;}
}
@media (max-width:639px){
    #cardLanding {
        width: 156px !important;
    }
    .card-title-descripcion{width: 125px;}
    .descripcion {font-size: 12px;}
}
@media (max-width:620px){
    .container-xl, .container-lg, .container-md, .container-sm, .container {max-width: 525px;}
}
@media (max-width:575px){
    .precioSInDescuento {margin-left: 30px;padding: 0px;}
}
@media (max-width:509px){    
    .colorTexto {margin-left: 1.4rem;}
}
@media (max-width:490px){    
    #btnCatalogo{width: 180px !important;}
}

@media (max-width:487px){
    .colorTexto, .textoTachado {margin-left: 2rem;}
}
@media (max-width:475px){
    .colorTexto{font-size: 18px!important;margin-left: 1rem;} .textoTachado {font-size: 11px!important;margin-left: 1rem;}
    #btnCatalogo {width: 177px !important;height: 30px;}
  /*   .colorTexto, .textoTachado {font-size: 13px!important;margin-left: 1rem;}
    #cardProducto,.textAgotado{font-size:11px}
    .divExclusivo{font-size:10px; margin-top: 11px;text-align: center;}
    .textStock{font-size:8px;margin-top:-7px}
    .precio{font-size:12px;margin-left:10px}
    .btn-outline-warning{margin-top:0}
    .textIvaIncluido{margin-top:-18px !important}
    #card{height:215px!important}
    #cardLanding{width:130px !important; height: 305px !important;}
    .precioSInDescuento{margin-left: 20px;text-align: center;display: grid;padding: 7px}
    .card-title-descripcion{width: 110px;height: 40px !important;}
    .descripcion { font-size: 10px;width: 114px}
    .imgProducto {width: 126px !important;height: 90px !important;margin-left: 0px !important;margin-top: 15px;}
 */}

 
@media (max-width:440px){.colorTexto{font-size: 20px!important;margin-left: 1rem!important;} .textoTachado {font-size: 10px!important;margin-left: 2rem;}}
@media (max-width:435px){
    .imgProducto {
        margin-left: 0px;
    }
}
@media (max-width:419px){.container-xl, .container-lg, .container-md, .container-sm, .container {max-width: 350px;}}
@media (max-width:410px){
    .descripcion { font-size: 12px;}
    .card-title-descripcion{width: 110px;height: 40px !important;}
}    
@media (max-width:390px){    
    #cardLanding{width:120px !important;}
    .imgProducto {margin-left: 4px!important;}
}
@media (max-width:377px){
    .container-xl, .container-lg, .container-md, .container-sm, .container {max-width: 310px;}
    .catalogoVertical{font-size:10px}
    #card{padding:5px 10px}
    .cardProducto{width:115px}
    #cardProducto{font-size:9px}
    .textStock{font-size:7px}
    .precio{font-size:13px;margin-left:7px}
    .imgProducto {margin-left: 8px!important;}
    .precioDespues{margin-left: -10px !important; }
    .card-title-descripcion{padding: 0px 10px 0px 10px;height: 30px !important;}
    #cardLanding{width:135px !important; height: 305px !important;}
    .btnLanding{height: 18px;width: 100px;margin-left: 9px;}
    .centrarVertical {display: inherit !important;}
    #cardLanding{width:126px !important;}
    .colorTexto {margin-left: 1rem!important;}
}
@media (max-width:378px){
    #cardLanding{height: 290px !important;}
    .colorTexto {font-size:17px!important; margin-left: 1rem !important;}
}
@media (max-width:320px){
    .container-xl, .container-lg, .container-md, .container-sm, .container {max-width: 300px;}
    .btnLanding{margin-top: 5px !important;}
    #btnCatalogo{width:100%;margin-top:12px;font-size:6px;height:16px}
    .centrarVertical{display:contents !important; font-size:10px}
    .card-text{margin-left:0px !important;font-size:8px; margin-top: -16px !important;}
    #card{height:265px}
    .imgProducto, #imgProducto {height: 98px !important;width: 98px !important; margin-left: 10%;}
    .card-title{font-size:5px;margin-left: -6px;}
    .textStock{margin-top:-25px}
    .textoTachado{margin-left:10px;font-size:9px}
    .precio{font-size:12px;margin-left:20px}
    .textIvaIncluido{margin-left: 8px !important;}
    .card-title-descripcion{height: 40px !important;}
    .conIva{margin-top: 10px !important;}
    #cardLanding{height: 270px !important;}
    .precioSInDescuento{margin-left: 30px;}
    .precioDespues{margin-left: 12px !important; }
}